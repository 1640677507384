import React, { useEffect, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from '../Stage.module.scss';
import { generateKey } from './../../../utils/helpers';

const YOUTUBE_API_SRC = 'https://www.youtube.com/player_api';

let asyncLoadYoutubeAPI;

const YoutubePlayer = forwardRef(({ videoId, host, aspectRatio, className = '', events, onReady, ...playerVars }, ref) => {
  const playerRef = useRef();
  useEffect(() => {
    if (!playerRef.current) 
      playerRef.current = {};
  });

  useEffect(() => {
    if (!playerRef.current.id) 
      playerRef.current.id = `yt_player_${generateKey()}`;

    let player;
    if (!asyncLoadYoutubeAPI) {
      asyncLoadYoutubeAPI = new Promise(resolve => {
        window.onYouTubeIframeAPIReady = () => resolve(window.YT);
        const tag = document.createElement('script');
        tag.src = YOUTUBE_API_SRC;
        document.body.appendChild(tag);
      });
    }
    asyncLoadYoutubeAPI.then(YT => {
      player = new YT.Player(playerRef.current.id, {
        videoId,
        host,
        playerVars: {
          origin: window.location.origin,
          rel: 0,
          showinfo: 0,
          ...playerVars,
        },
        events: {
          onReady: onPlayerReady,
          ...events,
        },
      });
    });
    function onPlayerReady() {
      onReady && onReady(player);
    }
    return () => player?.destroy();
  }, [videoId, events, playerVars, host, onReady]);

  return (
    <div className={`${styles.frame} ${className}`} style={{ paddingBottom: aspectRatio }} ref={ref}>
      <div ref={playerRef} />
    </div>
  );
});

YoutubePlayer.propTypes = {
  model: PropTypes.object,
};

export default React.memo(YoutubePlayer);