exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.45.47.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".VideoContentBlock__Stage_stage{height:100%}.VideoContentBlock__Stage_frame{padding-bottom:56.25%;position:relative}.VideoContentBlock__Stage_frame,.VideoContentBlock__Stage_frame>iframe{height:100%;width:100%}.VideoContentBlock__Stage_frame>iframe{border:0;display:block;left:0;margin:0;padding:0;position:absolute;top:0}.VideoContentBlock__Stage_spinner{left:50%;position:absolute;top:50%;transform:translate(-1em,-1em);transition:opacity .2s .2s}", ""]);

// exports
exports.locals = {
	"stage": "VideoContentBlock__Stage_stage",
	"frame": "VideoContentBlock__Stage_frame",
	"spinner": "VideoContentBlock__Stage_spinner"
};