import React from 'react';
import PropTypes from 'prop-types';
import { getVideoData, VideoProviderType } from './../../utils/helpers';
import YoutubePlayer from './players/YoutubePlayer';
import VimeoPlayer from './players/VimeoPlayer';
import Spinner from './spinner/Spinner';
import { useAppContext } from 'sana/context';
import styles from './Stage.module.scss';

const Stage = ({ model }) => {
  const { language: { cultureName } } = useAppContext();
  const videoData = getVideoData(model.url);
  return (
    <div className={`video-content-block ${styles.stage}`}>
      <div className={styles.spinner}><Spinner /></div>
      {
        videoData.provider === VideoProviderType.Youtube ?
          (
            <YoutubePlayer
              videoId={videoData.videoId}
              host={model.privacyEnhancedMode ? 'https://www.youtube-nocookie.com' : 'https://www.youtube.com'}
              rel="0"
              playsinline="1"
              modestbranding="1"
              playlist={videoData.videoId}
              loop={model.loop ? '1' : '0'}
              iv_load_policy="1"
              fs="1"
              enablejsapi="1"
              disablekb={model.showControls ? '1' : '0'}
              controls={model.showControls ? '1' : '0'}
              autoplay={model.autoplay ? '1' : '0'}
              hl={cultureName}
              onReady={player => {
                if (model.mute) {
                  player.mute();
                }
              }}
            />
          ) :
          (
            <VimeoPlayer
              videoId={videoData.videoId}
              autopause={false}
              autoplay={model.autoplay}
              byline={false}
              controls={model.showControls}
              keyboard={model.showControls}
              loop={model.loop}
              muted={model.mute}
              dnt={model.privacyEnhancedMode}
              playsinline
              texttrack={cultureName}
              title={false}
            />
          )
      }
    </div>
  );
};

Stage.propTypes = {
  model: PropTypes.object,
};

export default React.memo(Stage);